<template>
  <div>
    <b-card>
      <b-card-header
        style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
      >
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4><b style="color: cornflowerblue">Tambah Soal</b></h4>
          </b-card-title>
        </div>
        <b-nav>
          <b-nav-item>
            <router-link to="/admin/question">
              <b-button variant="primary">
                <feather-icon icon="ArrowLeftIcon" style="color: white" />
                <span class="align-right"
                  ><b style="color: white"> Kembali</b></span
                >
              </b-button>
            </router-link>
          </b-nav-item>
        </b-nav>
      </b-card-header>
      <b-form
        method="post"
        enctype="multipart/form-data"
        @submit.prevent="addData()"
      >
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Nama Kategori</label>
            <v-select
              name="register-kampus"
              placeholder="Cari Nama Kategori"
              v-model="id_category"
              :value="id"
              :reduce="(nama) => nama.id"
              :options="listCategories"
              label="nama"
            >
            </v-select>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 1</label>
            <v-select
              name="register-kampus"
              placeholder="Cari Tinjauan 1"
              v-model="tinjauansatuses_id"
              :value="id"
              :reduce="(nama) => nama.id"
              :options="listTinjauan1"
              label="nama"
            >
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 2</label>
            <v-select
              name="register-kampus"
              placeholder="Cari Tinjauan 2"
              v-model="tinjauanduas_id"
              :value="id"
              :reduce="(nama) => nama.id"
              :options="listTinjauan2"
              label="nama"
            >
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 3</label>
            <v-select
              name="register-kampus"
              placeholder="Cari Tinjauan 3"
              v-model="tinjauantigas_id"
              :value="id"
              :reduce="(nama) => nama.id"
              :options="listTinjauan3"
              label="nama"
            >
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 4</label>
            <v-select
              name="register-kampus"
              placeholder="Cari Tinjauan 4"
              v-model="tinjauanempats_id"
              :value="id"
              :reduce="(nama) => nama.id"
              :options="listTinjauan4"
              label="nama"
            >
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 5</label>
            <v-select
              name="register-kampus"
              placeholder="Cari Tinjauan 5"
              v-model="tinjauanlimas_id"
              :value="id"
              :reduce="(nama) => nama.id"
              :options="listTinjauan5"
              label="nama"
            >
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 6</label>
            <v-select
              name="register-kampus"
              placeholder="Cari Tinjauan 6"
              v-model="tinjauanenams_id"
              :value="id"
              :reduce="(nama) => nama.id"
              :options="listTinjauan6"
              label="nama"
            >
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 7</label>
            <v-select
              name="register-kampus"
              placeholder="Cari Tinjauan 7"
              v-model="tinjauantujuhs_id"
              :value="id"
              :reduce="(nama) => nama.id"
              :options="listTinjauan7"
              label="nama"
            >
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 8</label>
            <v-select
              name="register-kampus"
              placeholder="Cari Tinjauan 8"
              v-model="tinjauandelapans_id"
              :value="id"
              :reduce="(nama) => nama.id"
              :options="listTinjauan8"
              label="nama"
            >
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 9</label>
            <v-select
              name="register-kampus"
              placeholder="Cari Tinjauan 9"
              v-model="tinjauansembilans_id"
              :value="id"
              :reduce="(nama) => nama.id"
              :options="listTinjauan9"
              label="nama"
            >
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Isi Soal</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Isi Soal"
              v-model="question"
              rows="3"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Kata Kunci</label>
            <b-form-input
              id="kata kunci"
              v-model="kata_kunci"
              placeholder="Kata Kunci"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Pembahasan</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Pembahasan"
              v-model="pembahasan"
              rows="3"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Referensi</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Referensi"
              v-model="referensi"
              rows="3"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Upload Video</b></label>
            <b-form-group>
              <b-form-file
                v-if="uploadReady"
                ref="file"
                type="file"
                class="form-control"
                name="video"
                v-on:change="videoUpload($event.target)"
              />
            </b-form-group>
            <b-progress
              v-if="isLoading"
              animated
              :value="progressBar"
              :style="{ width: progressBar + '%' }"
              show-value
              :class="'progress-bar-info'"
            />
          </b-col>
        </b-row>
        <b-col md="6" xl="1" class="mb-1">
          <b-form-input
            hidden
            id="basicInput"
            v-model="hidden_video"
            placeholder="Name Video"
          />
        </b-col>
        <b-row>
          <b-col md="6" xl="4" class="mb-1" />
          <b-col md="6" xl="2" class="mb-1">
            <b-button variant="success" style="width: 100%" type="submit">
              <feather-icon icon="SaveIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Simpan</b></span
              >
            </b-button>
          </b-col>
          <b-col md="6" xl="2" class="mb-1">
            <b-button
              variant="danger"
              style="width: 100%"
              type="button"
              @click="cancelButton('info')"
            >
              <feather-icon icon="XCircleIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Batal</b></span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <!-- -------------------------------------- BATAS SUCI ---------------------------------->
    <b-card>
      <b-col cols="12">
        <!-- search input -->
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4>
              <b style="color: cornflowerblue">Pilih Soal</b>
            </h4>
          </b-card-title>
        </div>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span
              v-if="props.column.field === 'id'"
              class="btn-icon text-center"
            >
              {{ props.index + 1 }}
            </span>
            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="btn-icon text-center"
            >
              <!-- @click="
                  $router.push({
                    name: 'edit_question',
                    params: { id: props.row.id },
                  })
                " -->

              <!--
                  @click="editTaskData(props.row.id)"
                -->
              <b-button
                variant="gradient-success"
                class="btn-icon"
                @click="editTaskData(props.row.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              &nbsp;
              <b-button
                variant="gradient-danger"
                class="btn-icon"
                @click="deleteData(props.row.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '20', '50']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  :align="pagnation_posisition"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
      <!-- -------------------------------------- BATAS SUCI ---------------------------------->
      <br />
      <b-row
        v-if="
          this.cekIDrow == 'kosong' ||
          this.cekIDrow == null ||
          this.cekIDrow == undefined
        "
      ></b-row>
      <b-row v-else>
        <b-col md="6" xl="6" class="mb-1">
          <b-card>
            <b-card-header
              style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
            >
              <div class="d-flex align-items-center">
                <b-card-title>
                  <h4>
                    <b style="color: cornflowerblue"
                      >Soal yang dipilih {{ get_question.nama_category }}</b
                    >
                  </h4>
                </b-card-title>
              </div>
            </b-card-header>
            <b-form method="post" enctype="multipart/form-data">
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default"
                    ><b style="color: black">Nama Kategori:</b>
                    <b style="color: red">{{
                      get_question.nama_category
                    }}</b></label
                  >
                  <v-select
                    name="register-kampus"
                    placeholder="Cari Nama Kategori"
                    v-model="get_question.id_category"
                    :value="id"
                    :reduce="(nama) => nama.id"
                    :options="listCategories"
                    label="nama"
                  >
                  </v-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default"
                    ><b style="color: black">Tinjauan 1: </b
                    ><b style="color: red">{{
                      get_question.nama_tinjauan_satuses
                    }}</b></label
                  >
                  <v-select
                    name="register-kampus"
                    placeholder="Cari Tinjauan 1"
                    v-model="get_question.tinjauansatuses_id"
                    :reduce="(nama) => nama.id"
                    :options="listTinjauan1"
                    label="nama"
                  >
                  </v-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default"
                    ><b style="color: black">Tinjauan 2: </b
                    ><b style="color: red">{{
                      get_question.nama_tinjauan_duas
                    }}</b></label
                  >
                  <v-select
                    name="register-kampus"
                    placeholder="Cari Tinjauan 2"
                    v-model="get_question.tinjauanduas_id"
                    :reduce="(nama) => nama.id"
                    :options="listTinjauan2"
                    label="nama"
                  >
                  </v-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default"
                    ><b style="color: black">Tinjauan 3: </b
                    ><b style="color: red">{{
                      get_question.nama_tinjauan_tigas
                    }}</b></label
                  >
                  <v-select
                    name="register-kampus"
                    placeholder="Cari Tinjauan 3"
                    v-model="get_question.tinjauantigas_id"
                    :reduce="(nama) => nama.id"
                    :options="listTinjauan3"
                    label="nama"
                  >
                  </v-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default"
                    ><b style="color: black">Tinjauan 4: </b
                    ><b style="color: red">{{
                      get_question.nama_tinjauan_empats
                    }}</b></label
                  >
                  <v-select
                    name="register-kampus"
                    placeholder="Cari Tinjauan 4"
                    v-model="get_question.tinjauanempats_id"
                    :reduce="(nama) => nama.id"
                    :options="listTinjauan4"
                    label="nama"
                  >
                  </v-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default"
                    ><b style="color: black">Tinjauan 5: </b
                    ><b style="color: red">{{
                      get_question.nama_tinjauan_limas
                    }}</b></label
                  >
                  <v-select
                    name="register-kampus"
                    placeholder="Cari Tinjauan 5"
                    v-model="get_question.tinjauanlimas_id"
                    :reduce="(nama) => nama.id"
                    :options="listTinjauan5"
                    label="nama"
                  >
                  </v-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default"
                    ><b style="color: black">Tinjauan 6: </b
                    ><b style="color: red">{{
                      get_question.nama_tinjauan_enams
                    }}</b></label
                  >
                  <v-select
                    name="register-kampus"
                    placeholder="Cari Tinjauan 6"
                    v-model="get_question.tinjauanenams_id"
                    :reduce="(nama) => nama.id"
                    :options="listTinjauan6"
                    label="nama"
                  >
                  </v-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default"
                    ><b style="color: black">Tinjauan 7: </b
                    ><b style="color: red">{{
                      get_question.nama_tinjauan_tujuhs
                    }}</b></label
                  >
                  <v-select
                    name="register-kampus"
                    placeholder="Cari Tinjauan 7"
                    v-model="get_question.tinjauantujuhs_id"
                    :reduce="(nama) => nama.id"
                    :options="listTinjauan7"
                    label="nama"
                  >
                  </v-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default"
                    ><b style="color: black">Tinjauan 8: </b
                    ><b style="color: red">{{
                      get_question.nama_tinjauan_delapans
                    }}</b></label
                  >
                  <v-select
                    name="register-kampus"
                    placeholder="Cari Tinjauan 8"
                    v-model="get_question.tinjauandelapans_id"
                    :reduce="(nama) => nama.id"
                    :options="listTinjauan8"
                    label="nama"
                  >
                  </v-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default"
                    ><b style="color: black">Tinjauan 9: </b
                    ><b style="color: red">{{
                      get_question.nama_tinjauan_sembilans
                    }}</b></label
                  >
                  <v-select
                    name="register-kampus"
                    placeholder="Cari Tinjauan 9"
                    v-model="get_question.tinjauansembilans_id"
                    :reduce="(nama) => nama.id"
                    :options="listTinjauan9"
                    label="nama"
                  >
                  </v-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default">Isi Soal</label>
                  <b-form-textarea
                    id="textarea-default"
                    placeholder="Isi Soal"
                    v-model="get_question.question"
                    rows="3"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default">Kata Kunci</label>
                  <b-form-input
                    id="kata kunci"
                    v-model="get_question.kata_kunci"
                    placeholder="Kata Kunci"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default">Pembahasan</label>
                  <b-form-textarea
                    id="textarea-default"
                    placeholder="Pembahasan"
                    v-model="get_question.pembahasan"
                    rows="3"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default">Referensi</label>
                  <b-form-input
                    id="referensi"
                    v-model="get_question.referensi"
                    placeholder="Referensi"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default"
                    ><b>Upload Video {{ this.cekIDrow }}</b></label
                  >
                  <b-form-group>
                    <b-form-file
                      v-if="uploadReady"
                      ref="file"
                      type="file"
                      class="form-control"
                      name="video"
                      v-on:change="videoUpload($event.target)"
                    />
                  </b-form-group>
                  <b-progress
                    v-if="isLoading"
                    animated
                    :value="progressBar"
                    :style="{ width: progressBar + '%' }"
                    show-value
                    :class="'progress-bar-info'"
                  />
                </b-col>
              </b-row>
              <b-col md="6" xl="1" class="mb-1">
                <b-form-input
                  hidden
                  id="basicInput"
                  v-model="get_question.hidden_video"
                  placeholder="Name Video"
                />
              </b-col>
              <b-row>
                <b-col md="2" class="mb-1" />
                <b-col md="4" xl="4" class="mb-1">
                  <b-button
                    variant="primary"
                    style="width: 100%"
                    @click="updateData(get_question, cekIDrow)"
                  >
                    <feather-icon icon="CheckCircleIcon" style="color: white" />
                    <span class="align-middle"
                      ><b style="color: white"> Simpan</b></span
                    >
                  </b-button>
                </b-col>
                <b-col md="4" xl="4" class="mb-1">
                  <router-link to="/admin/list_question">
                    <b-button
                      variant="danger"
                      style="width: 100%"
                      type="button"
                    >
                      <feather-icon icon="XCircleIcon" style="color: white" />
                      <span class="align-middle"
                        ><b style="color: white"> Batal</b></span
                      >
                    </b-button>
                  </router-link>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
        <b-col md="6" xl="6" class="mb-1">
          <b-card>
            <b-card-header
              style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
            >
              <div class="d-flex align-items-center">
                <b-card-title>
                  <h4><b style="color: cornflowerblue">Kelola Jawaban</b></h4>
                </b-card-title>
              </div>
            </b-card-header>
            <b-form
              method="post"
              enctype="multipart/form-data"
              @submit.prevent="addAnswerData(cekIDrow)"
            >
              <b-table-simple hover small caption-top responsive>
                <colgroup>
                  <col />
                  <col />
                </colgroup>
                <colgroup>
                  <col />
                  <col />
                  <col />
                </colgroup>
                <colgroup>
                  <col />
                  <col />
                </colgroup>
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th colspan="10" class="text-center" width="90%">
                      Pilihan Ganda
                    </b-th>
                    <b-th colspan="1" class="text-center"> Jawab </b-th>
                    <b-th colspan="1" class="text-center"> Aksi </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody
                  v-for="(answer, index) in listAnswers"
                  :key="answer.id"
                  class="space_good"
                >
                  <b-tr>
                    <b-td class="text-left" colspan="10" width="90%">
                      {{ answer.answer }}
                    </b-td>
                    <b-td
                      class="text-center"
                      v-if="answer.is_correct == 0"
                      variant="danger"
                    >
                      <b v-if="index + 1 == 1"> (A)</b>
                      <b v-else-if="index + 1 == 2"> (B)</b>
                      <b v-else-if="index + 1 == 3"> (C)</b>
                      <b v-else-if="index + 1 == 4"> (D)</b>
                      <b v-else-if="index + 1 == 5"> (E)</b>
                      Salah
                    </b-td>
                    <b-td
                      class="text-center"
                      v-else-if="answer.is_correct == 1"
                      variant="success"
                    >
                      <b v-if="index + 1 == 1"> (A)</b>
                      <b v-else-if="index + 1 == 2"> (B)</b>
                      <b v-else-if="index + 1 == 3"> (C)</b>
                      <b v-else-if="index + 1 == 4"> (D)</b>
                      <b v-else-if="index + 1 == 5"> (E)</b>
                      Benar
                    </b-td>
                    <b-td
                      class="text-center"
                      width="8%"
                      style="padding-right: 0px; padding-left: 0px"
                    >
                      <b-nav>
                        <b-nav-item>
                          <b-button
                            variant="gradient-success"
                            class="btn-icon"
                            size="sm"
                            @click="
                              editTask(
                                answer.id,
                                answer.answer,
                                answer.is_correct
                              )
                            "
                          >
                            <feather-icon icon="EditIcon" />
                          </b-button>
                        </b-nav-item>
                        <b-nav-item>
                          <b-button
                            variant="gradient-danger"
                            class="btn-icon"
                            size="sm"
                            @click="deleteAnswersData(answer.id)"
                          >
                            <feather-icon icon="TrashIcon" />
                          </b-button>
                        </b-nav-item>
                      </b-nav>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <b-form-input
                    hidden
                    id="ID Jawaban"
                    v-model="answer_id"
                    placeholder="ID Jawaban"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default">Input Jawaban</label>
                  <b-form-input
                    id="Input Jawaban"
                    v-model="answer"
                    placeholder="Input Jawaban"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" xl="12" class="mb-1">
                  <label for="textarea-default"><b>Status Jawaban</b></label>
                  <b-form-select
                    id="is_correct"
                    v-model="is_correct"
                    :options="option_status"
                    placeholder="Status Jawaban"
                  />
                </b-col>
              </b-row>

              <b-row v-if="isEdit == 'Tidak'">
                <b-col md="4" class="mb-1"></b-col>
                <b-col md="4" class="mb-1">
                  <b-button variant="primary" block type="submit">
                    <feather-icon icon="CheckCircleIcon" style="color: white" />
                    Tambah
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col md="4" class="mb-1"></b-col>
                <b-col md="4" class="mb-1">
                  <b-button
                    variant="warning"
                    block
                    @click="updateAnswersData(cekIDrow)"
                  >
                    <feather-icon icon="CheckCircleIcon" style="color: white" />
                    Ubah
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BButton,
  BForm,
  BToast,
  BCardHeader,
  BNav,
  BCardTitle,
  BFormSelect,
  BNavItem,
  BFormInput,
  BRow,
  BFormCheckbox,
  BCol,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
import { VueGoodTable } from "vue-good-table/src";
export default {
  components: {
    VueGoodTable,
    BCard,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BForm,
    BTh,
    BFormCheckbox,
    BToast,
    BTd,
    ToastificationContent,
    BTbody,
    Swal,
    BTfoot,
    BButton,
    BCardHeader,
    BNav,
    BFormTextarea,
    BNavItem,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      id: this.$route.params.id,
      listCategories: [],
      listStudies: [],
      listTinjauan1: [],
      listTinjauan2: [],
      listTinjauan3: [],
      listTinjauan4: [],
      listTinjauan5: [],
      listTinjauan6: [],
      listTinjauan7: [],
      listTinjauan8: [],
      listTinjauan9: [],
      id_category: "",
      id_study: "",
      question: "",
      get_question: [],
      listAnswers: [],
      tinjauansatuses_id: 9999,
      tinjauanduas_id: 9999,
      tinjauantigas_id: 9999,
      tinjauanempats_id: 9999,
      tinjauanlimas_id: 9999,
      tinjauanenams_id: 9999,
      tinjauantujuhs_id: 9999,
      tinjauandelapans_id: 9999,
      tinjauansembilans_id: 9999,
      answer_id: "",
      referensi: "",
      pembahasan: "",
      kata_kunci: "",
      video: null,
      isEdit: "Tidak",
      isLoading: false,
      progressBar: 0,
      uploadReady: true,
      files: [],
      videos: [],
      hidden_video: "",
      cekIDrow: "kosong",
      //-----------------------------------------LIST-----------------------------------
      selected: "right",
      pagnation_posisition: "right",
      pageLength: 1,
      columns: [
        {
          label: "ID",
          field: "id",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Pertanyaan",
          field: "question",
        },
        {
          label: "Kategori",
          field: "nama_category",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Pelajaran",
          field: "nama_study",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      rows: [],
      rows2: [],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      //-----------------------------------------EDIT-----------------------------------
      getUrl: "",
      is_correct: "0",
      option_status: [
        { text: "Benar", value: "1" },
        { text: "Salah", value: "0" },
      ],
      answer: "",
    };
  },
  async mounted() {
    this.checkAuth();
    await this.getCategories();
    this.getStudies();
    this.getTinjauan1();
    this.getTinjauan2();
    this.getTinjauan3();
    this.getTinjauan4();
    this.getTinjauan5();
    this.getTinjauan6();
    this.getTinjauan7();
    this.getTinjauan8();
    this.getTinjauan9();
    this.getData();
    this.getUrl = process.env.VUE_APP_URL;
  },

  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      var levelCheck = localStorage.getItem("Ulevel");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      } else if (levelCheck != 2) {
        this.$router.push({ name: "dashboard" });
      }
    },
    async addData() {
      //   e.preventDefault();
      const formData = new FormData();
      formData.append("id_category", this.id_category);
      formData.append("id_study", this.id_study);
      formData.append("question", this.question);
      formData.append("tinjauansatuses_id", this.tinjauansatuses_id);
      formData.append("tinjauanduas_id", this.tinjauanduas_id);
      formData.append("tinjauantigas_id", this.tinjauantigas_id);
      formData.append("tinjauanempats_id", this.tinjauanempats_id);
      formData.append("tinjauanlimas_id", this.tinjauanlimas_id);
      formData.append("tinjauanenams_id", this.tinjauanenams_id);
      formData.append("tinjauantujuhs_id", this.tinjauantujuhs_id);
      formData.append("tinjauandelapans_id", this.tinjauandelapans_id);
      formData.append("tinjauansembilans_id", this.tinjauansembilans_id);
      formData.append("pembahasan", this.pembahasan);
      formData.append("kata_kunci", this.kata_kunci);
      formData.append("referensi", this.referensi);
      formData.append("video", this.video);
      formData.append("hidden_video", this.hidden_video);
      await this.$http
        .post(process.env.VUE_APP_BACKEND_URL + "questions/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            this.progressBar = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          }.bind(this),
        })
        .then((res) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Berhasil Ubah Data",
                variant: "success",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
          // this.$router.push({ name: "list_question" });
          this.getData();
          this.id_category = "";
          this.id_study = "";
          this.pembahasan = "";
          this.kata_kunci = "";
          this.video = "";
          this.question = "";
          this.referensi = "";
          this.hidden_video = "";
          //
          this.tinjauansatuses_id = "";
          this.tinjauanduas_id = "";
          this.tinjauantigas_id = "";
          this.tinjauanempats_id = "";
          this.tinjauanlimas_id = "";
          this.tinjauanenams_id = "";
          this.tinjauantujuhs_id = "";
          this.tinjauandelapans_id = "";
          this.tinjauansembilans_id = "";

          setTimeout(() => {
            this.photoReset();
            this.videoReset();
            this.isLoading = false;
            this.progressBar = 0;
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelButton(variant = null) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "XCircleIcon",
            text: "Cancel Perubahan Data",
            variant: "info",
          },
        },
        {
          timeout: 5000,
        },
        {
          position: "top-right",
        }
      );
      this.$router.push({ name: "list_question" });
      setTimeout(() => {
        this.photoReset();
        this.videoReset();
        this.isLoading = false;
        this.progressBar = 0;
      }, 2000);
    },
    getCategories() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "categories/view")
        .then((res) => {
          this.listCategories = res.data.data;
        });
    },
    getStudies() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "studies/view")
        .then((res) => {
          this.listStudies = res.data.data;
        });
    },
    getTinjauan1() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_satu/view")
        .then((res) => {
          this.listTinjauan1 = res.data.data;
        });
    },
    getTinjauan2() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_dua/view")
        .then((res) => {
          this.listTinjauan2 = res.data.data;
        });
    },
    getTinjauan3() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_tiga/view")
        .then((res) => {
          this.listTinjauan3 = res.data.data;
        });
    },
    getTinjauan4() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_empat/view")
        .then((res) => {
          this.listTinjauan4 = res.data.data;
        });
    },
    getTinjauan5() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_lima/view")
        .then((res) => {
          this.listTinjauan5 = res.data.data;
        });
    },
    getTinjauan6() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_enam/view")
        .then((res) => {
          this.listTinjauan6 = res.data.data;
        });
    },
    getTinjauan7() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_tujuh/view")
        .then((res) => {
          this.listTinjauan7 = res.data.data;
        });
    },
    getTinjauan8() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_delapan/view")
        .then((res) => {
          this.listTinjauan8 = res.data.data;
        });
    },
    getTinjauan9() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_sembilan/view")
        .then((res) => {
          this.listTinjauan9 = res.data.data;
        });
    },
    videoUpload(event) {
      this.video = event.files[0];
    },
    videoReset() {
      this.uploadReady = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
    async getData() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "questions/view")
        .then((res) => {
          this.rows = res.data.data;
        });
    },
    async deleteData(id) {
      await Swal.fire({
        title: "Ingin Hapus Data?",
        text: "Data akan dihapus?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .delete(
                process.env.VUE_APP_BACKEND_URL + `questions/delete/${id}`
              )
              .then((result) => {
                this.getData();
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Hapus Data",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Hapus Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    //---------------------------------------BATAS SUCI-------------------------------------
    async editTaskData(rowID) {
      this.cekIDrow = rowID;
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + `questions/search/` + rowID)
        .then((response) => {
          this.get_question = response.data.data[0];
        });

      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + `answers/search/` + rowID)
        .then((res) => {
          this.listAnswers = res.data.data;
          // console.log("asdasd",this.listAnswers)
        });
    },

    //---------------------------------------BATAS SUCI-------------------------------------

    updateData(question, rowID) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("video", this.video);
      formData.append("id_category", (this.id_category = question.id_category));
      formData.append("id_study", (this.id_study = question.id_study));
      formData.append(
        "tinjauansatuses_id",
        (this.tinjauansatuses_id = question.tinjauansatuses_id)
      );
      formData.append(
        "tinjauanduas_id",
        (this.tinjauanduas_id = question.tinjauanduas_id)
      );
      formData.append(
        "tinjauantigas_id",
        (this.tinjauantigas_id = question.tinjauantigas_id)
      );
      formData.append(
        "tinjauanempats_id",
        (this.tinjauanempats_id = question.tinjauanempats_id)
      );
      formData.append(
        "tinjauanlimas_id",
        (this.tinjauanlimas_id = question.tinjauanlimas_id)
      );
      formData.append(
        "tinjauanenams_id",
        (this.tinjauanenams_id = question.tinjauanenams_id)
      );
      formData.append(
        "tinjauantujuhs_id",
        (this.tinjauantujuhs_id = question.tinjauantujuhs_id)
      );
      formData.append(
        "tinjauandelapans_id",
        (this.tinjauandelapans_id = question.tinjauandelapans_id)
      );
      formData.append(
        "tinjauansembilans_id",
        (this.tinjauansembilans_id = question.tinjauansembilans_id)
      );
      formData.append("question", (this.question = question.question));
      formData.append("pembahasan", (this.pembahasan = question.pembahasan));
      formData.append("referensi", (this.referensi = question.referensi));
      formData.append(
        "hidden_video",
        (this.hidden_video = question.hidden_video)
      );
      formData.append("kata_kunci", (this.kata_kunci = question.kata_kunci));
      Swal.fire({
        title: "Apa sudah selesai?",
        text: "Perubahan data disesuaikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, sudah selesai!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(
                process.env.VUE_APP_BACKEND_URL + `questions/update/` + rowID,
                formData,
                {
                  // headers: {
                  //   "Content-Type": "multipart/form-data",
                  // },
                  // onUploadProgress: function (progressEvent) {
                  //   this.progressBar = parseInt(
                  //     Math.round(
                  //       (progressEvent.loaded * 100) / progressEvent.total
                  //     )
                  //   );
                  // }.bind(this)
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    this.progressBar = parseInt(
                      Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                      )
                    );
                  }.bind(this),
                }
              )
              .then((result) => {
                // this.$router.push({ name: "add_question" });
                this.getData();
                this.id_category = "";
                this.id_study = "";
                this.pembahasan = "";
                this.kata_kunci = "";
                this.video = "";
                this.question = "";
                this.referensi = "";
                this.hidden_video = "";
                //
                this.tinjauansatuses_id = "";
                this.tinjauanduas_id = "";
                this.tinjauantigas_id = "";
                this.tinjauanempats_id = "";
                this.tinjauanlimas_id = "";
                this.tinjauanenams_id = "";
                this.tinjauantujuhs_id = "";
                this.tinjauandelapans_id = "";
                this.tinjauansembilans_id = "";
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Simpan Perubahan Data",
                      variant: "success",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
                setTimeout(() => {
                  this.videoReset();
                  this.isLoading = false;
                  this.progressBar = 0;
                }, 2000);
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Perubahan Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    cancelButton() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "CheckCircleIcon",
            text: "Batal Ubah Data",
            variant: "info",
          },
        },
        {
          timeout: 5000,
        },
        {
          position: "top-right",
        }
      );
      this.$router.go();
      setTimeout(() => {
        this.videoReset();
        this.isLoading = false;
        this.progressBar = 0;
      }, 2000);
    },

    getAnswers() {
      this.$http
        .get(
          process.env.VUE_APP_BACKEND_URL + `answers/search/${this.cekIDrow}`
        )
        .then((res) => {
          this.listAnswers = res.data.data;
          // console.log("asdasd",this.listAnswers)
        });
    },
    async addAnswerData(rowID) {
      //   e.preventDefault();
      const formData = new FormData();
      formData.append("questions_id", rowID);
      formData.append("answer", this.answer);
      formData.append("is_correct", this.is_correct);
      await this.$http
        .post(process.env.VUE_APP_BACKEND_URL + "answers/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Berhasil Add Data",
                variant: "success",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
          this.getAnswers();
          this.answer = "";
          this.is_correct = 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editTask(id, answer, is_correct) {
      this.answer_id = id;
      this.answer = answer;
      this.is_correct = is_correct;
      this.isEdit = "Ya";
    },

    async updateAnswersData(rowID) {
      const formData = new FormData();
      formData.append("questions_id", rowID);
      formData.append("answer", this.answer);
      formData.append("is_correct", this.is_correct);
      await Swal.fire({
        title: "Apa sudah selesai?",
        text: "Perubahan data disesuaikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, sudah selesai!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(
                process.env.VUE_APP_BACKEND_URL +
                  `answers/update/${this.answer_id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((result) => {
                this.answer = "";
                this.is_correct = 0;
                this.isEdit = "Tidak";
                this.getAnswers();
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Simpan Perubahan Data",
                      variant: "success",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Perubahan Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    async deleteAnswersData(id) {
      await Swal.fire({
        title: "Ingin Hapus Data?",
        text: "Data akan dihapus?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, sudah selesai!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .delete(process.env.VUE_APP_BACKEND_URL + `answers/delete/${id}`)
              .then((result) => {
                this.getAnswers();
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Hapus Data",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Hapus Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
